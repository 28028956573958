.banner-hero {
    padding: 0px 65px 0px 15px;
    position: relative;
    max-width: 1770px;
    margin: 0 auto;
    .banner-inner {
        z-index: 5;
        position: relative;
        padding-left: 15px;
        padding-right: 15px;
        .banner-imgs {
            padding: 65px 0px 0px 0px;
            position: relative;
            height: 100%;
            min-height: 540px;
            .block-1 {
                position: absolute;
                top: 10%;
                left: -150px;
            }
            .block-2 {
                position: absolute;
                bottom: 0%;
                right: 120px;
            }
            .block-3 {
                position: absolute;
                top: 21%;
                right: 220px;
            }
            .block-4 {
                position: absolute;
                bottom: 8%;
                left: -80px;
            }
        }
    }
    .block-banner {
        position: relative;
        z-index: 12;
        padding: 50px 180px 80px 192px;
        .form-find {
            background: #ffffff;
            box-shadow: $box-shadow-1;
            border-radius: 8px;
            display: inline-block;
            width: 100%;
            padding: 10px 10px;
            form {
                display: flex;
                width: 100%;
            }
            .btn-find {
                min-width: 122px;
                padding-left: 34px;
                padding-right: 15px;
                background-image: url(../imgs/page/homepage1/search-icon.svg);
                background-repeat: no-repeat;
                background-position: left 19px top 18px;
            }
            .form-input {
                border: 0px solid $border-color-3;
                border-radius: 0px;
                padding: 10px 20px 10px 34px;
                display: inline-block;
                width: 100%;
            }
            .select2 {
                border: 0px solid $border-color-3;
                border-radius: 0px;
                padding: 10px 20px 10px 30px;
                display: inline-block;
                width: 100% !important;
                margin-right: 10px;
                position: relative;
                max-width: 180px;
                &::after {
                    content: "";
                    height: 18px;
                    width: 1.4px;
                    border-radius: 5px;
                    background-color: $color-border-1;
                    position: absolute;
                    top: 16px;
                    right: -3px;
                }
                &::before {
                    content: "";
                    background: url(../imgs/template/icons/icon-location-2.svg) no-repeat;
                    width: 18px;
                    height: 18px;
                    position: absolute;
                    left: 11px;
                    top: 15px;
                }
                .select2-selection__arrow {
                    background: url(../imgs/template/icons/arrow-down.svg) no-repeat right 10px center;
                    top: 11px;
                    width: 26px;
                    b {
                        display: none !important;
                        background-color: #9777fa;
                    }
                }
            }
            .box-industry {
                width: 100%;
                max-width: 180px;
                .select2 {
                    &::before {
                        content: "";
                        background: url(../imgs/template/icons/industry.svg) no-repeat;
                        width: 18px;
                        height: 18px;
                        position: absolute;
                        left: 11px;
                        top: 15px;
                    }
                }
            }
            .input-keysearch {
                background: url(../imgs/page/homepage1/key-numbers.svg) no-repeat left 10px center;
            }
            .input-industry {
                background: url(../imgs/template/icons/icon-job.svg) no-repeat left 18px center;
            }
        }
        .list-tags-banner {
            position: relative;
            a {
                color: $color-text-paragraph;
                font-size: $font-sm;
                margin-left: 2px;
                text-decoration: underline;
            }
            strong {
                font-weight: 700;
                font-family: $font-heading;
                font-size: $font-sm;
                color: $color-text-paragraph;
            }
        }
    }
    .heading-banner {
        color: $color-brand;
        font-size: 48px;
        line-height: 60px;
        color: $color-brand;
    }
    .color-brand-2 {
        color: $color-brand-2;
        position: relative;
        &::after {
            content: "";
            background-color: #3C65F5;
            height: 25px;
            position: absolute;
            width: 100%;
            left: 0;
            bottom: -6px;
            z-index: -1;
            opacity: 0.1;
        }
    }
    .banner-description {
        position: relative;
        font-size: $font-lg;
        color: $color-text-paragraph;
    }
    &.banner-image-single {
        padding: 20px 0px 20px 0px;
        img {
            width: 100%;
            border-radius: 16px;
        }
    }
    &.banner-company {
        padding: 40px 20px 60px 20px;
        background: #F2F6FD;
        border-radius: 16px;
        position: relative;
        .block-banner {
            padding: 0px 0px 0px 0px;
        }
    }
    &.banner-single {
        padding: 40px 20px 60px 20px;
        background: #F2F6FD;
        border-radius: 16px;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            bottom: 1px;
            right: 5px;
            height: 170px;
            width: 213px;
            background: url(../imgs/page/job-single/right-job-head.svg) no-repeat left bottom;
            background-size: contain;
        }
        &::before {
            content: "";
            position: absolute;
            bottom: 1px;
            left: -3px;
            height: 170px;
            width: 218px;
            background: url(../imgs/page/job-single/left-job-head.svg) no-repeat left bottom;
            background-size: contain;
            display: inline;
            top: auto;
            right: auto;
        }
    }
    &.hero-2 {
        position: relative;
        width: 100%;
        padding: 100px 0px 80px 0px;
        max-width: 100%;
        background: url(../imgs/page/homepage2/banner.png) no-repeat top center;
        background-size: cover;
        &::before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-color: $color-brand;
            opacity: 0.8;
            z-index: 1;
        }
        .banner-inner {
            z-index: 2;
            position: relative;
            max-width: 725px;
            margin: auto;
            width: 100%;
            .block-banner {
                padding: 0px;
                text-align: center;
                .list-tags-banner {
                    strong,
                    a {
                        color: $color-white;
                    }
                    strong {
                        margin-right: 10px;
                    }
                }
                .form-find {
                    .select2 {
                        text-align: left;
                    }
                }
            }
        }
    }
    &.hero-3
    {
        background: $color-brand-2;
        &::before {
            display: none;
        }
    }
}

@keyframes hero-thumb-animation {
    0% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes hero-thumb-sm-animation {
    0% {
        -webkit-transform: translateY(-20px) translateX(50px);
        -moz-transform: translateY(-20px) translateX(50px);
        -ms-transform: translateY(-20px) translateX(50px);
        transform: translateY(-20px) translateX(50px);
    }
    100% {
        -webkit-transform: translateY(-20px) translateX(0px);
        -moz-transform: translateY(-20px) translateX(0px);
        -ms-transform: translateY(-20px) translateX(0px);
        transform: translateY(-20px) translateX(0px);
    }
}

@keyframes hero-thumb-sm-2-animation {
    0% {
        -webkit-transform: translateY(-50px);
        -moz-transform: translateY(-50px);
        -ms-transform: translateY(-50px);
        transform: translateY(-50px);
    }
    100% {
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        transform: translateY(0px);
    }
}

.shape-1 {
    -webkit-animation: hero-thumb-animation 2s linear infinite alternate;
    -moz-animation: hero-thumb-animation 2s linear infinite alternate;
    -o-animation: hero-thumb-animation 2s linear infinite alternate;
    animation: hero-thumb-animation 2s linear infinite alternate;
}

.shape-2 {
    -webkit-animation: hero-thumb-sm-animation 4s linear infinite alternate;
    -moz-animation: hero-thumb-sm-animation 4s linear infinite alternate;
    -o-animation: hero-thumb-sm-animation 4s linear infinite alternate;
    animation: hero-thumb-sm-animation 4s linear infinite alternate;
}

.shape-3 {
    -webkit-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
    -moz-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
    -o-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
    animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
}