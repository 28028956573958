.swiper-group-9 .swiper-slide {
    text-align: center;
}

.list-brands {
    border-bottom: 1px solid $border-color;
    padding-bottom: 30px;
    a {
        img {
            opacity: 0.3;
            max-height: 20px;
            width: 100%;
        }
    }
}

.mh-none {
    max-height: 265px !important;
}