.archive-header {
    position: relative;
    top: -200px;
    margin-bottom: -200px;
    display: table;
    width: 100%;
    h3 {
        font-size: 46px;
    }
    .post-meta {
        .author {
            img {
                max-width: 30px;
                border-radius: 50%;
                margin-right: 5px;
            }
        }
    }
}
.card-blog-1 {
    border-radius: 15px;
    border: thin solid $border-color;
    padding: 30px;
    &:hover {
        box-shadow: $box-shadow-2;
    }
    .post-thumb {
        border-radius: 15px;
        overflow: hidden;
        img {
            border-radius: 15px;
        }
    }
    .post-meta {
        .author {
            img {
                max-width: 30px;
                border-radius: 50%;
                margin-right: 5px;
            }
        }
    }
    h3.post-title {
        font-size: 24px;
        line-height: 1.33;
        a {
            color: $color-brand;
            &:hover {
                color: $color-brand;
            }
        }
        &.small {
            font-size: 18px;
        }
    }
    .btn-brand-hover {
        border-color: $border-color-1-hover;
    }
    &.post-list {
        display: flex;
        .post-thumb {
            max-width: 275px;
            margin-right: 25px;
        }
    }
}

.single-body {
    figure img {
        border-radius: 15px;
    }
    p {
        font-size: $font-lg;
        margin-bottom: 15px;
        color: $color-text-paragraph;
        line-height: 1.5;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 30px 0 25px 0;
    }
    .excerpt {
        p {
            font-size: $font-2xl;
            line-height: 1.5;
            color: $color-brand;
        }
    }
    blockquote {
        background-color: $background-16;
        padding: 40px 60px;
        border-radius: 15px;
        margin: 30px auto;
        font-size: 24px;
        max-width: 80%;
        p {
            font-size: 24px;
            line-height: 32px;
            color: #7e7e7e;
            margin-bottom: 0;
        }
    }
}
.author-bio {
	border-radius: 0px;
	border: 0px solid $border-color;
    display: flex;
    h5 {
        margin: 0 !important;
        font-weight: 600;
    }
	.author-image {
		display: flex;
		justify-content: flex-start;
        min-width: 200px;
		img {
			margin-right: 15px;
            max-width: 56px;
            vertical-align: middle;
		}
		p {
			font-size: 14px;
		}
	}
    .author-des
    {
        padding: 25px;
        border: 1px solid $color-border-1;
        border-radius: 4px;
    }
}
.related-posts {
    h6 {
        font-size: 16px;
        font-weight: 600;
        a {
            color: $color-brand;
        }
    }
}
.author
{
    span
    {
        color: $color-black-1;
    }
}
.gallery-3
{
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    li
    {
        flex: 0 0 auto;
        width: 33.33%;
        padding: 5px;
        margin-bottom: 10px;
        a
        {
            img
            {
                border-radius: 4px;
            }
        }
    }
}
.box-white
{
    background: $background-white;
    padding: 50px 0px;
}
.img-middle
{
    float: left;
    margin-top: 2px;
}
.max-width-single
{
    max-width: 733px;
    width: 100%;
    margin: auto;
}
.list-comments
{
    li
    {
        ul
        {
            li
            {
                padding-left: 55px;
                .author-bio 
                {
                    .author-image {
                        min-width: 200px;
                    }
                }
            }
        }
    }
}
.form-comment 
{
    .box-agree input {
        float: left;
        margin: 5px 0px 0px 0px;
    }
    .box-agree span {
        display: block;
        padding-left: 25px;
    }
    .input-comment {
        border-radius: 4px;
        border: 2px solid $color-border-2;
        padding: 20px;
        width: 100%;
        color: $color-text-mutted;
        font-size: $font-lg;
        line-height: 26px;
        font-weight: 500;
        min-height: 150px;
    }
}